<template>
  <div ref="templateViewer" class="h-full"></div>
  <!-- <component :is="'style'" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Anton&family=Caveat:wght@400;500;600;700&family=Libre+Baskerville:wght@400;700&family=PT+Sans+Narrow:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,500;1,700&family=Ranga:wght@400;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&family=Rubik+Bubbles&family=Send+Flowers&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,400;1,700;1,900&family=Tajawal:wght@300;400;500;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,700&family=Vazirmatn:wght@300;400;500;600;700;800;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Changa:wght@200;300;400;500;600;700;800&family=Dancing+Script:wght@400;500;600&family=Domine:wght@400;500;700&family=Fjalla+One&family=Grape+Nuts&family=Inter:wght@200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Lobster&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');
  </component> -->
  <component :is="'style'" scoped v-if="dynamicTemplate && dynamicTemplate.style">
    {{ dynamicTemplate.style }}
  </component>
  <component :is="'style'" scoped v-if="!isPreviewMode && form && form.placeholder_color">
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: {{ form.placeholder_color }} !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color:  {{ form.placeholder_color }} !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color:  {{ form.placeholder_color }} !important;
    }
    :-moz-placeholder { /* Firefox 18- */
      color:  {{ form.placeholder_color }} !important;
    }
  </component>
  <component :is="'style'" scoped>
    .fname-span:empty{
        padding-left: 5px;
    }
  </component>
</template>
<script>
/* eslint-disable */
window.selfEmit=null
import {defineComponent,toRefs, watch} from "vue";
import {createApp} from 'vue/dist/vue.esm-bundler.js'
import { mapActions } from "vuex";
import store from "@/store/index.js";
import "vue3-circle-progress/dist/circle-progress.css"
import CircleProgress from "vue3-circle-progress"

export default defineComponent({
  props:{
    dynamic_template:{
      type:Object
    },
    quiz_template:{
      type:Object
    },
    questions_data:{
      type:Array
    },
    template_css_data:{
      type:Array
    },
    quiz_data:{
      type:Object
    },
    is_preview_mode:{
      type:Boolean,
      default:false
    },
  },
  components: {
    CircleProgress,
  },
  setup(props) {
    const {dynamic_template: dynamicTemplate} = toRefs(props);
    const {questions_data: questions} = toRefs(props);
    const {template_css_data: templateCssData} = toRefs(props);
    const {quiz_data: quizData} = toRefs(props);
    const {is_preview_mode: isPreviewMode} = toRefs(props);
    return {dynamicTemplate,questions,templateCssData,quizData,isPreviewMode};
  },
  data() {
    return {
       app:null,
    }
  },
  computed:{
    form(){
      return this.templateCssData.find((css) => (css.type == 'form'));
    },
  },
  mounted(){
    this.handle()
  },
  watch:{
    quizData(newVal){
      if (newVal) { 
        this.handle()
      }
    }
  },
  methods : {
    handle() {
      if(!this.dynamicTemplate)
      return false
    let me=this;
    var x = window.matchMedia("(max-width: 480px)")
    let themeApp=createApp({
      template:me.dynamicTemplate.base,
      //data
      data: () => {
        return {
          questions: me.questions,
          answers: {},
          qIndex: 0,
          quizId: 1,
          templateCss : me.templateCss,
          selectedSetting : 'main',
          currentView : 'editor',
          templateCssData : me.templateCssData,
          assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
          currentQuestion : null,
          firstName : '',
          screen: x.matches ? 'mobile' : 'desktop',
          quizData: me.quizData,
          isPreviewMode : me.isPreviewMode,
          lastIndex : 0,
          isLoading : false,
          flag:false,
          emailFlag:false,
          timer : null,
          showTextEdit : false
        };
      },
      components: {
        CircleProgress,
      },
      watch: {
        question(newQuestion, oldQuestion) {
          this.setCurrentQuestionQuiz(newQuestion)
          if(newQuestion && newQuestion.type == 'visual-splash-question')
            this.setSetting('video')
          else if(newQuestion && newQuestion.type == 'image-splash-question')
            this.setSetting('image')
          else
            this.setSetting('main')
        }
      },
      //computed-data
      computed: {
        main(){
          return this.templateCssData.find((css) => (css.type == 'main'));
        },
        headline(){
          return this.templateCssData.find((css) => (css.type == 'headline'));
        },
        description(){
          return this.templateCssData.find((css) => (css.type == 'description'));
        },
        progress(){
          return this.templateCssData.find((css) => (css.type == 'progress'));
        },
        form(){
          return this.templateCssData.find((css) => (css.type == 'form'));
        },
        button(){
          return this.templateCssData.find((css) => (css.type == 'button'));
        },
        action_button(){
          return this.templateCssData.find((css) => (css.type == 'action_button'));
        },
        single_splash(){
          return this.templateCssData.find((css) => (css.type == 'single_splash'));
        },
        multi_splash(){
          return this.templateCssData.find((css) => (css.type == 'multi_splash'));
        },
        outerRootDiv(){
          // var baseCss = 'max-height: calc(100vh - 210px) !important; overflow-y: scroll;';
          var baseCss = '';
          var bgCss = ''
          if(this.main && this.main.bg_color && this.main.bg_type == 'image' && this.main.bg_image){
            bgCss = this.getImageBackgroundCss()
          }else if(this.main && this.main.bg_color && this.main.bg_type == 'gradient'){
            bgCss = this.getGradientBackgroundCss()
          }else{
            bgCss = `background-color: ${this.main && this.main.bg_color ? this.main.bg_color : ''}; `
          }
          return `${baseCss} ${bgCss}`
        },
        boxColor(){
          return `background-color: ${this.main && this.main.box_color ? this.main.box_color : '#FFFFFF'}`
        },
        boxClass(){
          if(this.main && this.main.template_size == 1){
            return 'w-full ease-in-out md:min-h-[726px] flex flex-col bg-[#438bf6] min-h-[600px] md:mx-auto montserat relative  pb-6 pt-0 md:pb-6 overflow-x-hidden'
          }else if(this.main && this.main.template_size == 2){
            return 'w-full ease-in-out md:min-h-[726px] flex flex-col bg-[#438bf6] min-h-[600px] md:mx-auto montserat relative pb-6 pt-0 md:pb-6 overflow-x-hidden md:rounded-[48px] md:max-w-[950px]'
          }else if(this.main && this.main.template_size == 3){
            return 'w-full ease-in-out md:min-h-[726px] flex flex-col bg-[#438bf6] min-h-[600px] md:mx-auto montserat relative pb-6 pt-0 md:pb-6 overflow-x-hidden md:rounded-[48px] md:max-w-[550px]'
          }
        },
        cssStyleAll: function () {
          return function (style_for) {
            let comman_style=`font-family:${
                this[style_for] &&  this[style_for]['font_family']
                    ? this[style_for]['font_family']
                    : "Poppins"
            } !important ;font-size:${
                this[style_for] &&  this[style_for]['font_size'] && this.screen == 'desktop'
                    ? this[style_for]['font_size'] + "px"
                    : ""
            } ;line-height : ${
               this[style_for] && this[style_for]['line_height'] && this.screen == 'desktop'
                    ? (this[style_for]['line_height'] + "em")
                    : "normal"
            } ;margin-top : ${
               this[style_for] && this[style_for]['spacing_before']
                    ? (this[style_for]['spacing_before'] > 0 ? this[style_for]['spacing_before'] + "px" : "0px")
                    : "0px"
            } ;margin-bottom : ${
               this[style_for] && this[style_for]['spacing_after']
                    ? (this[style_for]['spacing_after'] > 0 ? this[style_for]['spacing_after'] + "px" : "0px")
                    : "0px"
            } ;outline-color : ${
              this[style_for] &&  this[style_for]['active_color']
              ?  this[style_for]['active_color']
              : ""
            } !important;`;

            let background=''
            let baseCss = ''
            if(this[style_for] && this[style_for]['gradient_color_1'] && this[style_for]['gradient_color_1'] && this[style_for]['style'] == 'gradient'){
              baseCss = '-webkit-text-fill-color: transparent;background-clip: text !important;-webkit-background-clip: text !important;'
              let angle=''
              if(this[style_for]['gradient_angle']){
                  angle = this[style_for]['gradient_angle']+'deg'
              }
              let gradient_stop_1 = ''
              if(this[style_for]['gradient_color_stop_1']){
                  gradient_stop_1 = this[style_for]['gradient_color_stop_1']+'%'
              }
              let gradient_stop_2 = ''
              if(this[style_for]['gradient_color_stop_2']){
                  gradient_stop_2 = this[style_for]['gradient_color_stop_2']+'%'
              }
              if(angle){
                    background = `background: linear-gradient(${angle},${this[style_for]['gradient_color_1']} ${gradient_stop_1}, ${this[style_for]['gradient_color_2']} ${gradient_stop_2});`
              }else{
                    background = `background: linear-gradient(${this[style_for]['gradient_color_1']} ${gradient_stop_1}, ${this[style_for]['gradient_color_2']} ${gradient_stop_2});`
              }
            }else{
              background =`color:${
                this[style_for] &&  this[style_for]['text_color']
                  ? this[style_for]['text_color']
                  : ""
              }!important ; background : ${
                this[style_for] &&  this[style_for]['bg_color']
                  ?  this[style_for]['bg_color']
                  : ""
              }!important ;`
            }
           
            return `${comman_style} ${background} ${baseCss}`
          }
        },
        activeCssStyleAll: function () {
          return function (style_for) {
            let comman_style=`font-family:${
                this[style_for] &&  this[style_for]['font_family']
                    ? this[style_for]['font_family']
                    : "Poppins"
            } !important ;font-size:${
                this[style_for] &&  this[style_for]['font_size'] && this.screen == 'desktop'
                    ? this[style_for]['font_size'] + "px"
                    : ""
            } ;line-height : ${
               this[style_for] && this[style_for]['line_height'] && this.screen == 'desktop'
                    ? (this[style_for]['line_height'] + "em")
                    : "normal"
            } ;margin-top : ${
               this[style_for] && this[style_for]['spacing_before']
                    ? (this[style_for]['spacing_before'] > 0 ? this[style_for]['spacing_before'] + "px" : "0px")
                    : "0px"
            } ;margin-bottom : ${
               this[style_for] && this[style_for]['spacing_after']
                    ? (this[style_for]['spacing_after'] > 0 ? this[style_for]['spacing_after'] + "px" : "0px")
                    : "0px"
            } !important;`;
            let background=''
            background =`color:${
              this[style_for] &&  this[style_for]['text_color']
                ? this[style_for]['text_color']
                : ""
            }!important ; background : ${
              this[style_for] &&  this[style_for]['active_color']
                ?  this[style_for]['active_color']
                : ""
            }!important ;`
            return `${comman_style} ${background}`
          }
        },
        btnStyle() {
          let comman_style=`font-family:${
              this.button &&  this.button.font_family
                  ? this.button.font_family
                  : "Poppins"
          } !important ;font-size:${
              this.button &&  this.button.font_size && this.screen == 'desktop'
                  ? this.button.font_size + "px"
                  : ""
          } ;line-height : ${
              this.button && this.button.line_height && this.screen == 'desktop'
                  ? (this.button.line_height + "em")
                  : "normal"
          } ;margin-top : ${
              this.button && this.button.spacing_before
                  ? (this.button.spacing_before > 0 ? this.button.spacing_before + "px" : "0px")
                  : "0px"
          } ;margin-bottom : ${
              this.button && this.button.spacing_after
                  ? (this.button.spacing_after > 0 ? this.button.spacing_after + "px" : "0px")
                  : "0px"
          } !important;`;
          let background=''
            background =`color:${
              this.button &&  this.button.text_color
                ? this.button.text_color
                : ""
            }!important ; background : ${
              this.button &&  this.button.bg_color && this.button.style && this.button.style == 'solid'
                ?  this.button.bg_color
                : ""
            }!important ; border: 1px solid ${
              this.button &&  this.button.bg_color && this.button.style
                ?  this.button.bg_color
                : ""
            } !important;`
          return `${comman_style} ${background}`
        },
        question() {
          return this.questions[this.qIndex];
        },
        isLastQuestion() {
          return this.qIndex === this.questions.length - 1
        },
        isDataVisible(){
            return this.currentQuestion == 'single-splash-question' || this.currentQuestion == 'multi-splash-question' || this.currentQuestion == 'first-name-question' || this.currentQuestion == 'percentage-question' || this.currentQuestion == 'image-options-question' || this.currentQuestion == 'visual-splash-question' || this.currentQuestion == 'image-splash-question' ? false : true
        },
        isFullScreenLayout(){
            return this.currentQuestion == 'single-splash-question' || this.currentQuestion == 'multi-splash-question' || this.currentQuestion == 'first-name-question' || this.currentQuestion == 'percentage-question' || this.currentQuestion == 'visual-splash-question' || this.currentQuestion == 'image-splash-question' ? false : true
        },
        logoImageSrc() {
          if (this.main.logo_image && this.main.logo_image.search('blob:') == -1) {
              return this.assetUrl + '/storage/' + this.main.logo_image
          } else if (this.main.logo_image) {
              return this.main.logo_image
          } else {
                return require('@/assets/images/img/no-image.png')
          }
        },
        isLogoBar(){
            return (this.quizData && !this.quizData.add_logo_bar) || (this.currentQuestion == 'visual-splash-question' || this.currentQuestion == 'image-splash-question') ? false : true
        }, 
        isSplashes(){
            return (this.currentQuestion == 'visual-splash-question' || this.currentQuestion == 'image-splash-question') ? false : true
        }, 
      },
      mounted(){
        this.setCurrentQuestionQuiz(this.question)
        if(this.question && this.question.type == 'visual-splash-question')
          this.setSetting('video')
        else if(this.question && this.question.type == 'image-splash-question')
          this.setSetting('image')
        else
          this.setSetting('main')
        //Set Dropdown value null
        if (this.question.type === "dropdown-question" && !this.answers[this.question.id]) this.answers[this.question.id] = null;
      },
      //methods
      methods: {
        ...mapActions({
          setSelectedSetting:'Quiz/setSelectedSetting',
          setCurrentQuestionQuiz:'Quiz/setCurrentQuestion',
        }),
        goToNext(type=null) {
          this.lastIndex = this.qIndex
          if((type == 'button' || type == 'form') && this.currentView == 'editor'){
            this.setSetting(type)
          }else{
            this.setSetting('main')
            if (this.qIndex < this.questions.length - 1) {
              this.qIndex++;
              this.question.last_index = this.lastIndex
              if (this.question.type === "dropdown-question" && !this.answers[this.question.id]) this.answers[this.question.id] = null;
            }
            // if(this.question.type === "single-splash-question"){
            //     this.setSplashInterval()
            // }
          }
        },
        goToPrevious() {
          if (this.qIndex > 0) {
            this.qIndex--;
          }
           this.lastIndex = this.qIndex
          // if(this.question.type === "single-splash-question"){
          //     this.setSplashInterval()
          // }
        },
        setSetting(data){
          var selected_setting = null
          if(data == 'headline' || data == 'description' || data == 'progress' || data == 'action_button' || data == 'single_splash' || data == 'multi_splash'){
            selected_setting = 'text_setting'
          }else if(data == 'main'){
            selected_setting = 'main_setting'
          }else if(data == 'form'){
            selected_setting = 'form_setting'
          }else if(data == 'button'){
            selected_setting = 'button_setting'
          }else if(data == 'video'){
            selected_setting = 'video_setting'
          }else if(data == 'image'){
            selected_setting = 'image_setting'
          }
          var payload = {
            editor_type : data,
            selected_setting : selected_setting
          }
          this.setSelectedSetting(payload)
        },
        getImageBackgroundCss() {
          var raw_background = '';
          let overlayColor=''
          if(this.main && this.main.bg_image){
            var image= this.main.bg_image.search('blob:') == -1
            ? 'url(' + this.assetUrl + '/storage/' + this.main.bg_image + ')'
            : 'url(' + this.main.bg_image + ')';
            raw_background=`background-image:${image};
                    ${this.getImageSettingCss()}
            `;
          }
            
          if(this.main.color_overlay){
            overlayColor=`background-blend-mode: multiply; background-color: ${this.main.color_overlay};`
          }
          return `${raw_background} ${overlayColor}`
        },
        getImageSettingCss(){
          let image_setting=this.main.image_position ? this.main.image_position : 'bgCover'
          let image_bg_size= image_setting == 'bgCover' ? 'cover' : image_setting == 'bgCover101' ? '100% 100%' : '100% auto';
          let image_bg_repeat= image_setting == 'bgCover' ? 'repeat repeat' : image_setting == 'bgCover101' ? 'no-repeat' : 'no-repeat';
          let image_bg_position= image_setting == 'bgCover' ? 'center center' : '0% 0%';
          return  `
            background-size: ${image_bg_size};
            -webkit-background-size${image_bg_size};
            background-repeat: ${image_bg_repeat};
            background-position:${image_bg_position};
            ${image_setting == 'bgCover' ? 'background-attachment:fixed;' : ''}`
        },
        getGradientBackgroundCss(){
          let angle=''
          if(this.main.gradient_angle){
              angle = this.main.gradient_angle+'deg'
          }
          let gradient_stop_1 = ''
          if(this.main.gradient_color_stop_1){
              gradient_stop_1 = this.main.gradient_color_stop_1+'%'
          }
          let gradient_stop_2 = ''
          if(this.main.gradient_color_stop_2){
              gradient_stop_2 = this.main.gradient_color_stop_2+'%'
          }
          let background;
          let gradient = ''
            if(angle){
                gradient = `linear-gradient(${angle},${this.main.gradient_color_1} ${gradient_stop_1}, ${this.main.gradient_color_2} ${gradient_stop_2});`
          }else{
                gradient = `linear-gradient(${this.main.gradient_color_1} ${gradient_stop_1}, ${this.main.gradient_color_2} ${gradient_stop_2});`
          }
          return background = `background: ${gradient}`; 
        },
        setCurrentQuestion(data){
          this.currentQuestion = data
        },
        setFirstName(name){
          this.firstName = name
        },
        replaceData(word,string,replaceWith){
          if(string != '' && string != null){
            if(replaceWith == 'first_name'){
              return string.replace(word,this.firstName)
            }
          }
          return null
        },
        setSplashInterval(){
          if(this.quizData && !this.quizData.add_customizable_button)
              var durationSec = this.question.message_duration > 0 ? this.question.message_duration : 5;
          else
              var durationSec = this.question.message_duration
          var timer;
          if(durationSec > 0){
              clearTimeout(timer);
              timer = setTimeout(() => {
                  this.goToNext()
              }, (durationSec * 1000));
          }
        },
        setTextEditStatus(status){
            this.showTextEdit = status
        }
      },
    })
    let fieldList = [
      "email-question",
      "phone-no-question",
      "contact-information-question",
      "short-box-question",
      "name-question",
      "comment-box-question",
      "dropdown-question",
      "options-question",
      "yes-no-question",
      "single-splash-question",
      "multi-splash-question",
      "first-name-question",
      "percentage-question",
      "rating-question",
      "date-question",
      "image-options-question",
      "visual-splash-question",
      "image-splash-question",
    ];

    let fields = ``;
    for (const field of fieldList) {
      fields =
          fields +
          `<field-${field} v-if="question.type=='${field}'"/>`;
          themeApp.component(`field-${field}`, {
            template: me.dynamicTemplate[field.replace(/-/g, '_')],
            data() {
              return {
                splashResponse : null,
                screen: x.matches ? 'mobile' : 'desktop',
                currentView : 'editor',
                quizData: me.quizData,
                assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
              }
            },
            computed: {
              inputModel: {
                get() {
                  return this.$root.answers[this.$root.qIndex];
                },
                set(value) {
                  this.$root.answers[this.$root.qIndex] = value;
                },
              },
              question() {
                return this.$root.questions[this.$root.qIndex];
              },
              answers() {
                return this.$root.answers;
              },
              form() {
                return this.$root.form;
              },
              cssStyle: function () {
                return this.$root.cssStyleAll('form')
              },
              btnStyle: function () {
                return this.$root.btnStyle
              },
              singleSplashCssStyle: function () {
                return this.$root.cssStyleAll('single_splash')
              },
              multiSplashCssStyle: function () {
                return this.$root.cssStyleAll('multi_splash')
              },
              headlineCssStyle: function () {
                return this.$root.cssStyleAll('headline')
              },
              activeStyle: function () {
                return this.$root.activeCssStyleAll('form')
              },
              textStyle(){
                let comman_style=`font-family:${
                    this.form &&  this.form.font_family
                        ? this.form.font_family
                        : "Poppins"
                } !important ;color:${
                    this.form &&  this.form.text_color
                        ? this.form.text_color
                        : ""
                } !important ;font-size:${
                    this.form &&  this.form.font_size
                        ? this.form.font_size + "px"
                        : ""
                } ;line-height : ${
                  this.form && this.form.line_height
                        ? (this.form.line_height + "em")
                        : "normal"
                } !important;`;
                return `${comman_style}`
              },
              isLastQuestion() {
                return this.$root.isLastQuestion
              },
              flag() {
                  return this.$root.flag
              },
              emailFlag() {
                  return this.$root.emailFlag
              },
              isLoading() {
                  return this.$root.isLoading
              },
              imageSplashStyle(){
                  var raw_background = '';
                  let overlayColor=''
                  let image=''
                  if(this.question.library && this.question.library.image_type != 'custom'){
                      image= this.question.library.image_url ? 'url(' + this.question.library.image_url + ')' : 'url(https://images.pexels.com/photos/4101555/pexels-photo-4101555.jpeg)';
                  }else{
                      if(this.question.library && this.question.library.upload_url){
                          image = this.question.library.upload_url.search('blob:') == -1 ? 'url(' + this.assetUrl + '/storage/' + this.question.library.upload_url + ')' : 'url(' + this.question.library.upload_url + ')';
                      }
                  }
                  raw_background=`background-image:${image};
                      background-size: cover;
                      -webkit-background-size : cover;
                      background-repeat: repeat;
                      background-position: center center;
                      background-attachment:fixed;`;
                  if(this.question.library && this.question.library.image_overlay){
                      overlayColor=`background-blend-mode: multiply !important; background-color: ${this.question.library.image_overlay} !important;`
                  }
                  return `${raw_background} ${overlayColor}`
              },
              validationTextColor(){
                return `color: ${this.form && this.form.bar_color ? this.form.bar_color : '#ef4444'}`
              },
              isLogoBar(){
                  return this.$root.isLogoBar
              }, 
              isSplashes(){
                  return this.$root.isSplashes
              }, 
              headline(){
                  return this.$root.headline
              }, 
              showTextEdit() {
                  return this.$root.showTextEdit
              },
            },
            mounted() {
              this.setCurrentQuestion()
              if(field == 'multi-splash-question')
                this.setSplashResponse()
              // if(field == 'percentage-question')
              //   this.setProgressbarData()
              if(field == 'first-name-question'){
                  this.setCaretPosition()
              }
            },
            methods: {
              goToNext(type=null) {
                this.$root.goToNext(type);
              },
              goToPrevious() {
                this.$root.goToPrevious();
              },
              setSetting(data) {
                this.$root.setSetting(data);
              },
              setCurrentQuestion() {
                this.$root.setCurrentQuestion(field);
              },
              setNameData(questionData,event){
                if(questionData.type == 'first-name-question'){
                  var name = event.target.value
                  this.$root.setFirstName(name)
                }
              },
              replaceData(word,string,replaceWith){
                return this.$root.replaceData(word,string,replaceWith)
              },
              setSplashResponse(){
                var previousQuestion = this.$root.questions[this.$root.qIndex -1];
                if(previousQuestion){
                  var selectedInputId = null
                  Object.keys(this.answers).find(key => {
                    if(parseInt(key) == previousQuestion.id){
                      selectedInputId = parseInt(this.answers[key])
                    }
                  });
                  var selectedSplash =  this.question.inputs.find((input)=> input.previous_input_id == selectedInputId)
                  if(selectedSplash && selectedSplash.field_value)
                      this.splashResponse = selectedSplash.field_value
                  else
                      this.splashResponse = 'Please submit previous question answer'
                }
              },
              setOptionResponse(question,input){
                if(question.is_multiple){
                    var inputArray = []
                    if(this.answers[question.id]){
                        var index = this.answers[question.id].indexOf(input.id)
                        if(index !== -1){
                            this.answers[question.id].splice(index,1)
                        }else{
                            this.answers[question.id].push(input.id)
                        }
                    }else{
                        this.answers[question.id] = inputArray
                        this.answers[question.id].push(input.id)
                    }
                }else{
                    this.answers[question.id] = input.id
                    this.goToNext('form')
                }
              },
              setProgressbarData() {
                  // var xvaluenow = Math.floor((Math.random() * 100) + 0); //Generates a random number (0-100) only for demonstration
                  var xvaluenow = this.question.complete_percentage
                  var rotatenum = 'rotate(' + xvaluenow * 1.8 + 'deg)';
                  var progress = document.getElementById('progress');
                  var progress_circle = document.getElementById('progress-circle');
                  // var progress_style = document.getElementById('progress-style');
                  if (xvaluenow == 0) {
                      var shadowfix = "0";
                  }
                  else {
                      var shadowfix = "1px";
                  }
                  progress.innerHTML = xvaluenow + '%';
                  progress_circle.setAttribute("aria-valuenow", xvaluenow);  
                  const progressStyle = document.createElement('style');
                  progressStyle.innerHTML = " \
                      .p-h:before, .p-f, .p-f:before{ \
                      -moz-transform: " + rotatenum + "; \
                      -webkit-transform: " + rotatenum + "; \
                      -o-transform: " + rotatenum + "; \
                      -ms-transform: " + rotatenum + "; \
                      transform: " + rotatenum + "; \
                      -webkit-box-shadow: 0 0 0 " + shadowfix + " #828282; \
                      box-shadow: 0 0 0 " + shadowfix + " #828282;}\
                      \ ";
                  document.head.appendChild(progressStyle);
              },
              setInputAnswer(questionData,event){
                  if(questionData.type == 'first-name-question'){
                      if(event.target.innerText){
                          var name=event.target.innerText
                      }else{
                          var name= '' 
                          this.$root.setTextEditStatus(false)
                      }
                      this.$root.setFirstName(name)
                      this.answers[this.question.id] = name
                  }
              },
              checkInput(e){
                  this.$root.setTextEditStatus(true)
                  setTimeout(() => {
                    this.$refs.firstnameBox.innerText = e.data || " "
                    var selectedText = window.getSelection();
                    var selectedRange = document.createRange();
                    selectedRange.setStart(this.$refs.firstnameBox, 1);
                    selectedRange.collapse(true);
                    selectedText.removeAllRanges();
                    selectedText.addRange(selectedRange);
                    this.$refs.firstnameBox.focus()
                  }, 10);
              },
              setCaretPosition(){
                  setTimeout(() => {
                      var el = this.$refs.firstNameSpan;
                      var range = document.createRange();
                      var sel = window.getSelection();
                      range.setStart(el.childNodes[0], 0);
                      range.collapse(true);
                      sel.removeAllRanges();
                      sel.addRange(range);
                      el.focus();
                  }, 10);
              }
            },
          });
        }
        themeApp.component("fields", {
          template: fields,
          computed: {
            question() {
              return this.$root.questions[this.$root.qIndex];
            }
          },
        });

        themeApp.config.errorHandler = (err, instance, info) => {
          // handle error, e.g. report to a service
          console.log('err',err)
          console.log('instance',instance)
          console.log('info',info)
        }
        themeApp.use(store);
        themeApp.mount(this.$refs.templateViewer);
        // this.app=themeApp;
    }
  },
  unmounted() {
    // if(this.app) this.app.unmount();
    // this.app=null;
  },
})
</script>
<style scoped>
.max-screen-class{
  max-height: calc(100vh - 200px) !important;
}
.theme-height-editor{
  height: calc(100vh - 210px) !important;
}
</style>